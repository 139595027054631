import type { Backend } from '~api'
import type { BackendTradingTransactionsConnection, TradingTransactions, TradingTransactionsConnectionListeners } from '~api/common/backend'
import PhoenixConnection, { PhoenixConnectionListeners } from './phoenix-connection'

interface Listeners extends TradingTransactionsConnectionListeners, PhoenixConnectionListeners<TradingTransactions> {}

export default class MiPasaTradingTransactionsConnection
  extends PhoenixConnection<Listeners, TradingTransactions>
  implements BackendTradingTransactionsConnection
{
  constructor(backend: Backend, projectId?: string) {
    super(backend, `/api/mipasa/socket`, `trading_transactions:${projectId || 'all'}`)
  }

  requestPage(page: number, perPage = 10) {
    return this.sendCommand('switch_page', {
      page,
      per_page: perPage,
    })
  }

  async connect() {
    if (this.channel) {
      this.disconnect()
    }

    super.connect()
  }
}
