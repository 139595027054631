import type { TradingMarketBundle } from '~api/exchange/types'

const DEFAULT_PRECISION = 0.00001

export function getPrecision(exchangesMarkets: TradingMarketBundle[][], currencyOrMarketSymbol: string) {
  // We need to use exchangeInstance.markets instead of exchangeInstance.currencies because the latter isn't populated for binance.
  // To do so, we need to find best exchange & market symbol to resolve a market object to get the amount precision.
  const precision = findPrecision(exchangesMarkets, currencyOrMarketSymbol)

  return precision || DEFAULT_PRECISION
}

function findPrecision(exchangesMarkets: TradingMarketBundle[][], currencyOrMarketSymbol: string): number | undefined {
  const markets = exchangesMarkets.flat()
  const currencyOrMarketSymbolUppercased = currencyOrMarketSymbol.toUpperCase()

  // Best hit is actual market symbol - we're mostly not using that and we're using single currency but we can for most accurate outcome
  let marketSymbol = currencyOrMarketSymbolUppercased
  let market = markets.find(bundle => bundle.symbol === marketSymbol)
  if (market) {
    return market.precision.amount
  }

  // Then we prefer pairing with USDT
  marketSymbol = `${currencyOrMarketSymbolUppercased}/USDT`
  market = markets.find(bundle => bundle.symbol === marketSymbol)
  if (market) {
    return market.precision.amount
  }

  // Then we fallback to any market matching on base asset
  market = markets.find(bundle => bundle.symbol.startsWith(`${currencyOrMarketSymbolUppercased}/`))
  if (market) {
    return market.precision.amount
  }

  // Then we prefer pairing in reverse way with USDT
  // (there's no market where specified currency would be a base asset)
  marketSymbol = `USDT/${currencyOrMarketSymbolUppercased}`
  market = markets.find(bundle => bundle.symbol === marketSymbol)
  if (market) {
    return market.precision.price
  }

  // Then we fallback to any market matching on quote asset
  market = markets.find(bundle => bundle.symbol.endsWith(`${currencyOrMarketSymbolUppercased}/`))
  if (market) {
    return market.precision.amount
  }

  return undefined
}
