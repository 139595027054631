// eslint-disable-next-line max-classes-per-file
import { useConstCallback } from '@unbounded/unbounded-components'
import { useEffect, useRef, useState } from 'react'

/*
 * A Signal<T> is a const reference to T, where you can subscribe to value updates of it.
 * It allows a global variable or context that does not rerender all of it's children, but also can go through memo() and useMemo().
 */

export class ImmutableSignal<T> {
  protected value: T

  private callbacks: Array<(value: T) => void> = []

  constructor(initial: T) {
    this.value = initial
  }

  public get current() {
    return this.value
  }

  public addCallback(cb: (value: T) => void) {
    if (!this.callbacks.includes(cb)) {
      this.callbacks.push(cb)
    }
  }

  public removeCallback(cb: (value: T) => void) {
    const indexInList = this.callbacks.indexOf(cb)

    if (indexInList !== -1) {
      this.callbacks.splice(indexInList, 1)
    }
  }

  protected callCallbacks() {
    this.callbacks.forEach(cb => cb(this.value))
  }

  public trigger() {
    this.callCallbacks()
  }
}

export class Signal<T> extends ImmutableSignal<T> {
  public get current() {
    return this.value
  }

  public set current(newValue: T) {
    if (newValue !== this.value) {
      this.value = newValue
      this.callCallbacks()
    }
  }
}

type SignalCombinerCallback<T, R> = (inputs: R[]) => T

export class CombinedSignal<T, R> extends ImmutableSignal<T> {
  private childSignals: ImmutableSignal<R>[]

  private callback: SignalCombinerCallback<T, R>

  private subscriptionCount = 0

  constructor(cb: SignalCombinerCallback<T, R>, childSignals: ImmutableSignal<R>[]) {
    const initialValue = cb(childSignals.map(x => x.current))
    super(initialValue)
    this.callback = cb
    this.childSignals = childSignals
  }

  override addCallback(cb: (value: T) => void) {
    super.addCallback(cb)
    this.subscriptionCount++
    if (this.subscriptionCount === 1) {
      this.childSignals.forEach(child => child.addCallback(this.exportedCallCallbacks))
    }
  }

  override removeCallback(cb: (value: T) => void) {
    if (this.subscriptionCount === 1) {
      this.childSignals.forEach(child => child.removeCallback(this.exportedCallCallbacks))
    }
    this.subscriptionCount--
    super.removeCallback(cb)
  }

  private exportedCallCallbacks = () => {
    const newValue = this.callback(this.childSignals.map(x => x.current))

    if (newValue !== this.value) {
      this.value = newValue
      this.callCallbacks()
    }
  }
}

export function useNewSignal<T>(initialValue: T) {
  const signalRef = useRef<Signal<T>>(new Signal(initialValue))

  return signalRef.current
}

export function useSignalCallback<T>(signal: ImmutableSignal<T> | undefined, callback: (newValue: T) => void) {
  useEffect(() => {
    signal?.addCallback(callback)

    return () => signal?.removeCallback(callback)
  }, [signal])
}

// We don't support `undefined` DO NOT TRY
// `ImmutableSignal<T> | undefined` type will not cause any rerender
// when value will change from `undefined` to something
export default function useSignal<T>(signal: ImmutableSignal<T>) {
  const [localState, setLocalState] = useState<T>(signal.current)
  const effectCallback = useConstCallback(newValue => setLocalState(newValue))

  useSignalCallback(signal, effectCallback)

  return localState
}
