import Paths from '~routes/paths'

export const EXTERNAL_PATHS = {
  miPasaVideoCreatingFirstProject: Paths.documentation.format({ slug: 'general-documentation', '*': 'videos/create-your-first-mipasa-project' }),
  miPasaDocsKeyboardShortcuts: Paths.documentation.format({ slug: 'general-documentation', '*': 'once-you-re-in/in-depth-keyboard-shortcuts' }),
  miPasaFirstDocumentationPage: Paths.documentation.format({
    slug: 'general-documentation/once-you-re-in/first-things-first-let-s-create-a-new-project',
  }),
  jiraManageAPITokens: 'https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/',
  jiraUseJQL: 'https://support.atlassian.com/jira-service-management-cloud/docs/use-advanced-search-with-jira-query-language-jql/',
  pasaNetworkDocumentation: Paths.documentation.format({ slug: 'learn-pasa' }),
  pasaNetworkFAQ: Paths.documentation.format({ slug: 'learn-pasa', '*': 'faq' }),
  pasaNetworkGettingStarted: Paths.documentation.format({ slug: 'learn-pasa', '*': 'getting-started' }),
}
