// This is the base connection type that will be used in place of direct WebSocket with APIs.
// It allows typing sent and received messages.
// This base class serves as both class and interface.

export interface ConnectionError {
  message: string
}

export interface BaseConnectionListeners {
  error: (error: ConnectionError) => void
  open: () => void
  close: (error?: ConnectionError) => void
}

export default abstract class BaseConnection<T extends BaseConnectionListeners> {
  private listeners: Record<string, Array<any>> = {}

  addEventListener<K extends keyof T & string>(name: K, listener: T[K]) {
    const newListeners = this.listeners[name] || []

    if (newListeners.indexOf(listener) === -1) {
      newListeners.push(listener)
    }

    this.listeners[name] = newListeners
  }

  removeEventListener<K extends keyof T & string>(name: K, listener: T[K]) {
    const listeners = this.listeners[name]

    if (!listeners) {
      return
    }

    const indexToRemove = listeners.indexOf(listener)

    if (indexToRemove !== -1) {
      listeners.splice(indexToRemove, 1)
      if (!listeners.length) {
        delete this.listeners[name]
      }
    }
  }

  // The args type here means that "args" is the args taken by the listener type under specific key.
  // For example, if listener takes (notebookId: string), the real function signature will be (name: K, notebookId: string)
  protected callListeners<K extends keyof T & string>(name: K, ...args: T[K] extends (...argsInfer: infer P) => any ? P : never[]) {
    const listeners = this.listeners[name] || []

    listeners.forEach(listener => {
      listener(...args)
    })
  }

  abstract connect(): Promise<void>
  abstract disconnect(): void
}
