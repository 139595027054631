import type { Backend } from '~api'
import type { BackendTrackingConnection, TrackingEvent } from '~api/common/backend'
import PhoenixConnection, { PhoenixConnectionListeners } from './phoenix-connection'

interface Listeners extends PhoenixConnectionListeners<object> {}

export default class MiPasaTrackingConnection extends PhoenixConnection<Listeners, object> implements BackendTrackingConnection {
  constructor(backend: Backend) {
    super(backend, `/api/mipasa/socket`, `tracking`)
  }

  sendEvent(event: TrackingEvent) {
    return this.sendCommand('event', {
      type: event.type,
      client_data: event.clientData,
      url: event.url,
      title: event.title,
    })
  }

  async connect() {
    if (this.channel) {
      this.disconnect()
    }

    super.connect()
  }
}
