import type {
  Backend,
  BackendFileChannelConnection,
  BackendFileChannelConnectionListeners,
  BackendFileChannelLockResponse,
} from '../../common/backend'
import PhoenixConnection, { PhoenixConnectionListeners } from './phoenix-connection'

interface Listeners extends BackendFileChannelConnectionListeners, PhoenixConnectionListeners<BackendFileChannelLockResponse> {}

export default class MiPasaFileChannelConnection
  extends PhoenixConnection<Listeners, BackendFileChannelLockResponse>
  implements BackendFileChannelConnection
{
  constructor(backend: Backend, projectId: string, fileId: string) {
    super(backend, `/api/mipasa/socket`, `file:${projectId}/${fileId}`)
  }

  takeover(): void {
    return this.sendCommand('takeover')
  }

  async connect() {
    if (this.channel) {
      this.disconnect()
    }

    super.connect()
  }
}
