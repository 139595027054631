const innerGetFileExtension = (fileName: string): [string, string] => {
  const extIndex = fileName.lastIndexOf('.')

  if (extIndex === -1) {
    return [fileName, '']
  }

  return [fileName.substring(0, extIndex), fileName.substring(extIndex + 1)]
}

export const getFileExtension = (fileName: string): string => innerGetFileExtension(fileName)[1]

export function getFileName(filePath: string): string {
  const lastSep = filePath.lastIndexOf('/')

  return lastSep !== -1 ? filePath.substring(lastSep + 1) : filePath
}

export const getFileNameWithoutExtension = (fileName: string): string => innerGetFileExtension(fileName)[0]

export function getFileParentPath(filePath: string): string {
  const lastSep = filePath.lastIndexOf('/')

  return lastSep !== -1 ? filePath.substring(0, lastSep) : ''
}

// For a file like a/b/c/d.txt, this function returns
// ['a', 'a/b', 'a/b/c']
export function getFileParentsPaths(filePath: string): string[] {
  const paths = filePath.split('/')

  if (paths.length === 1) {
    return []
  }

  // Remove the file name itself from list
  paths.splice(paths.length - 1, 1)

  return paths.reduce((acc, path, index) => {
    const parentPath = acc[index - 1] || ''

    return [...acc, `${parentPath}${parentPath ? '/' : ''}${path}`]
  }, [] as string[])
}

export function cleanupFilenameForVariable(name: string) {
  return name.trim().replaceAll(/[^A-Za-z0-9_]/g, '')
}
