import { BaseColor, GlobalStyles, PlatformLoader, ThemeMode, Typography, spacing, themeColor, themes } from '@unbounded/unbounded-components'
import React, { useMemo } from 'react'
import ReactDOM from 'react-dom'
import { matchPath } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import { prepareTelegramMiniApp } from '~apps/pasa-tma/PrepareTelegramMiniApp'
import { THEME_PREFERENCES_VERSION } from '~consts'
import Paths from '~routes/paths'
import { CURRENT_PLATFORM, CURRENT_PLATFORM_TYPE, Platform, isTelegramMiniApp } from '~routes/platform'
import '~routes/platformBackends' // Very essential to have it here

/* not entirely correct, but does not work if loaded dynamically */
import '@fortune-sheet/react/dist/index.css'
import 'monaco-editor/min/vs/base/browser/ui/codicons/codicon/codicon.ttf'
import 'react-image-crop/dist/ReactCrop.css'
import 'reactflow/dist/style.css'
// Generated swagger-ui-react v5.11.0 CSS with css vars replacements
// see https://github.com/YellowKirby/swagger-ui-themeable
// TODO use as UN library https://hacera.atlassian.net/browse/PAP-3844
import './swagger-ui.min.css'

const { name: platformName } = CURRENT_PLATFORM

const LoaderStyle = styled.div`
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const LoaderHiddenString = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  font-size: 0;
  color: transparent;
`

const LoaderPleaseWait = styled.div`
  color: ${themeColor(BaseColor.shade_70)};
  margin-top: ${spacing(0.5)};
  ${Typography.titleMedium};
  ${Typography.textSemiStrong};
  text-align: center;
`

const Loader: React.FC<{ isEmbed?: boolean; requestedMode?: ThemeMode }> = ({ isEmbed, requestedMode }) => {
  const mode = useMemo(() => {
    try {
      const { searchParams } = new URL(document.URL)
      // Get Embed `theme` param
      let currentMode = requestedMode || (searchParams.get('theme') as ThemeMode | null)

      if (!currentMode) {
        const themeRaw = window.localStorage?.getItem('codeTheme') || '{}'
        const theme = JSON.parse(themeRaw) as
          | {
              id: string
              title: string
              mode: ThemeMode
              version?: number
            }
          | null
          | undefined

        currentMode = theme?.version === THEME_PREFERENCES_VERSION ? theme?.mode : null
      }

      return currentMode || CURRENT_PLATFORM.themeDefaultMode
    } catch (e) {
      // Do nothing
    }

    return CURRENT_PLATFORM.themeDefaultMode
  }, [])

  let caption

  switch (CURRENT_PLATFORM_TYPE) {
    case Platform.crypto:
      caption = 'Mi Pasa es 2 Pasa...'
      break
    default:
      caption = `${platformName} is starting...`
  }

  return (
    <ThemeProvider theme={themes[CURRENT_PLATFORM.themes[mode]]}>
      <GlobalStyles />
      <LoaderStyle>
        <PlatformLoader platform={CURRENT_PLATFORM_TYPE} />
        {/* We use it to trick Chrome to not pick up the app language from the visible loader caption */}
        <LoaderHiddenString>{CURRENT_PLATFORM.title}</LoaderHiddenString>
        <LoaderPleaseWait>{isEmbed ? `Loading embedded content...` : caption}</LoaderPleaseWait>
      </LoaderStyle>
    </ThemeProvider>
  )
}

window.addEventListener('DOMContentLoaded', async () => {
  const node = document.getElementById('root')

  if (!node) {
    return
  }

  const { telegramThemeMode } = await prepareTelegramMiniApp()

  const isEmbed = Boolean(
    matchPath(Paths.legacyEmbed.pattern, window.location.pathname) ||
      matchPath(Paths.embed.pattern, window.location.pathname) ||
      matchPath(Paths.embedCanvas.pattern, window.location.pathname),
  )

  ReactDOM.render(<Loader requestedMode={telegramThemeMode} isEmbed={isEmbed} />, node)

  const entrypoint = await import(isTelegramMiniApp() ? './apps/pasa-tma/entrypoint' : './entrypoint')

  await entrypoint.default(node)
})
