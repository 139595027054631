import type {
  Backend,
  BackendCollaboratorLobbyConnection,
  BackendCollaboratorLobbyConnectionListeners,
  BackendCollaboratorLobbyConnectionPresenceCustomMeta,
} from '~api'
import PhoenixConnection, { PhoenixConnectionListeners } from './phoenix-connection'

interface Listeners extends BackendCollaboratorLobbyConnectionListeners, PhoenixConnectionListeners<object> {}

export default class MiPasaCollaboratorLobbyConnection extends PhoenixConnection<Listeners, object> implements BackendCollaboratorLobbyConnection {
  constructor(backend: Backend, projectId: string) {
    super(backend, `/api/mipasa/socket`, `collaborator_lobby:${projectId}`)
  }

  updateMeta(meta: BackendCollaboratorLobbyConnectionPresenceCustomMeta) {
    return this.sendCommand('update_meta', meta)
  }

  async connect() {
    if (this.channel) {
      this.disconnect()
    }

    super.connect()
  }
}
