/**
 * Parses size from bytes to human-readable
 * source: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 */
const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

export function formatBytesToHumanReadable(bytes: number) {
  let l = 0
  let n = parseInt(`${bytes}`, 10) || 0

  // eslint-disable-next-line
  while (n >= 1024 && ++l) {
    n /= 1024
  }

  return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`
}
