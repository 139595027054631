import type { Dictionary, Exchange, Market, Tickers } from 'ccxt'
import type { Backend } from '~api'
import { captureError } from '~utils/monitoring'

type IDemoExchange = Pick<Exchange, 'id' | 'name' | 'has' | 'markets' | 'tickers' | 'loadMarkets' | 'fetchTickers' | 'setSandboxMode'>

export default class DemoExchange implements IDemoExchange {
  private readonly apiBackend: Backend

  readonly id: string = 'demo'

  readonly name: string = 'Pasa Demo'

  readonly has: Dictionary<boolean | 'emulated'> = {
    fetchTickers: true,
  }

  markets: Dictionary<Market> = {}

  tickers: Tickers = {}

  constructor(apiBackend: Backend) {
    this.apiBackend = apiBackend
  }

  async loadMarkets(): Promise<Dictionary<Market>> {
    try {
      const markets = await this.apiBackend.fetchTradingDemoMarkets()

      if (markets) {
        this.markets = markets
      }
    } catch (e) {
      captureError(e, { label: `Error loading trading markets for Pasa Demo` })
    }

    return this.markets
  }

  async fetchTickers(): Promise<Tickers> {
    try {
      const tickers = await this.apiBackend.fetchTradingDemoTickers()

      if (tickers) {
        this.tickers = tickers
      }
    } catch (e) {
      captureError(e, { label: `Error loading trading tickers for Pasa Demo` })
    }

    return this.tickers
  }

  setSandboxMode(isEnabled: boolean) {}
}
