const getFormatter = (options: Intl.NumberFormatOptions = {}) =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, ...options })

export function formatToCurrency(value: number, options: Intl.NumberFormatOptions = {}) {
  return getFormatter(options).format(value)
}

export function formatToCurrencyNumber(value: number, options: Intl.NumberFormatOptions = {}) {
  const formatted = getFormatter({ ...options, currencyDisplay: 'code' })
    .format(value)
    .replaceAll(/\s|USD/g, '')
  return formatted === '-0' || formatted === '+0' ? '0' : formatted
}

export function formatToCustomCurrency(value: number, currency: string, options: Intl.NumberFormatOptions = {}) {
  return `${formatToCurrencyNumber(value, options)} ${currency || ''}`
}
