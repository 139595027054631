import { Socket, SocketConnectOption } from 'phoenix'

interface PhoenixSocketConnectOption extends SocketConnectOption {
  updateConnectParams: () => Promise<void>
}

export default class PhoenixSocket extends Socket {
  updateConnectParams: (() => Promise<void>) | undefined

  constructor(endPoint: string, opts?: Partial<PhoenixSocketConnectOption>) {
    super(endPoint, opts)
    this.updateConnectParams = opts?.updateConnectParams
  }

  async connectAsync(params?: unknown) {
    if (this.updateConnectParams) {
      await this.updateConnectParams()
    }
    super.connect(params)
  }

  connect(params?: unknown) {
    this.connectAsync(params)
  }
}
