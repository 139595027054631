import type { FileId } from '~data/file/file-type'
import type { BackendCollaborationConnection, BackendCollaborationConnectionListeners } from '../../common/backend'
import WebSocketConnection from './websocket-connection'

export default class PapillonCollaborationConnection
  extends WebSocketConnection<BackendCollaborationConnectionListeners>
  implements BackendCollaborationConnection
{
  constructor(fileId: FileId) {
    const wsProto = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
    const wsUrl = `${wsProto}//${window.location.host}/wsapi/projects/editFile/${fileId}`

    super(wsUrl)
    this.connect()
  }

  processMessage(message: any) {
    const { type } = message

    if (type === 'broadcast') {
      this.callListeners('broadcast', message)
    } else if (type === 'add_user') {
      this.callListeners('add_user', message)
    } else if (type === 'remove_user') {
      this.callListeners('remove_user', message)
    } else if (type === 'status') {
      this.callListeners('status', message)
    } else if (type === 'state') {
      this.callListeners('state', message)
    }
  }

  sendBroadcast(payload: any): void {
    this.sendMessage({ type: 'broadcast', payload })
  }
}
