import { captureError } from '~utils/monitoring'
import BaseConnection, { BaseConnectionListeners, ConnectionError } from '../../common/connection'

export default class WebSocketConnection<T extends BaseConnectionListeners> extends BaseConnection<T> {
  protected readonly url: string

  protected ws?: WebSocket

  constructor(url: string) {
    super()
    this.url = url
  }

  // gross TypeScript hack.
  // it does not recognize "this" as WebSocketConnectionListener<BaseConnectionListeners> due to T being generic.
  // to fix later somehow (not sure how), but this is better than @ts-ignore
  private eventProducer(): WebSocketConnection<BaseConnectionListeners> {
    return this
  }

  async connect() {
    this.ws = new WebSocket(this.url)
    this.ws.onopen = () => {
      this.eventProducer().callListeners('open')
    }
    this.ws.onclose = event => {
      let error: ConnectionError | undefined

      if (event.code !== 1000) {
        error = { message: event.reason || `WebSocket closed with code ${event.code}` }
        this.eventProducer().callListeners('error', error)
      }
      this.eventProducer().callListeners('close', error)
    }
    this.ws.onerror = e => {
      this.eventProducer().callListeners('error', { message: 'Unknown WebSocket error' })
    }
    this.ws.onmessage = event => {
      let message

      try {
        message = JSON.parse(event.data)
      } catch (e) {
        captureError(e)
        this.eventProducer().callListeners('error', { message: 'Failed to interpret server message as JSON' })
      }

      if (!message) {
        return
      }

      this.processMessage(message)
    }
  }

  protected processMessage(_message: any) {}

  protected sendMessage(message: any) {
    if (!this.ws || this.ws.readyState !== 1) {
      throw new Error('Message could not be delivered: WebSocket is closed')
    }

    this.ws.send(JSON.stringify(message))
  }

  disconnect() {
    if (!this.ws) {
      return
    }

    this.ws.close(1000, 'Client requested disconnection')
    this.ws = undefined
  }
}
