import type { Backend, BackendNotificationsConnection, BackendNotificationsConnectionListeners } from '~api'
import PhoenixConnection, { PhoenixConnectionListeners } from './phoenix-connection'

interface Listeners extends BackendNotificationsConnectionListeners, PhoenixConnectionListeners<object> {}

export default class MiPasaNotificationsConnection extends PhoenixConnection<Listeners, object> implements BackendNotificationsConnection {
  constructor(backend: Backend, userId: string) {
    super(backend, `/api/mipasa/socket`, `notifications:${userId}`)
  }

  readNotification(id: string): void {
    this.sendCommand('notification_read', { id })
  }

  async connect() {
    if (this.channel) {
      this.disconnect()
    }

    super.connect()
  }
}
