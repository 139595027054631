import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import type { ApplicationConfig } from '~routes/platform'

interface UserMeta {
  id?: string
  username: string
}

// Call this before initializing the app and it'll automatically record the following:
// - unhandled errors
// - errors handled by React error boundary
//
// Use other functions from this module to record anything else than that.
export function initialize(config: ApplicationConfig, opts?: { user?: UserMeta }) {
  if (!config.integrations.sentry.enabled) {
    return
  }

  Sentry.init({
    dsn: config.integrations.sentry.dsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
      }),
    ],
    environment: config.integrations.sentry.environment,
    tunnel: config.integrations.sentry.tunnel,
    tracesSampleRate: 0,
  })

  if (opts?.user) {
    setUser(opts.user)
  }
}

export function setUser(user: UserMeta) {
  Sentry.setUser({ id: user.id, username: user.username })
}

// Note that Sentry will record `payload`` only 2 levels deep, so for example this:
//
//     captureError(e, { payload: { l1: { l2x: 1, l2y: { l3: 1 } } }})
//
// will look like this on Sentry:
//
//     {"l1":{"l2x":1,"l2y":"[Object]"}}
export function captureError(error: any, opts?: { label?: string; payload?: any }) {
  const context: Record<string, any> = { tags: { capture: 'manual' } }

  if (opts?.label || opts?.payload) {
    context.extra = {}
    if (opts?.label) {
      context.extra.label = opts.label
    }
    if (opts?.payload) {
      context.extra.payload = opts.payload
    }
  }

  if (error instanceof Error) {
    Sentry.captureException(error, context)
  } else if (typeof error === 'string' || error?.message) {
    // We can't capture error-like objects as Sentry exception,
    // so just capture a message instead (mostly for Redux `SerializedError`)
    Sentry.captureMessage(error?.message || error, context)
  } else {
    const cantCaptureMessage = `failed to capture error: ${JSON.stringify(error)}`
    Sentry.captureMessage(cantCaptureMessage, context)
    console.error(cantCaptureMessage)
  }

  const consoleLabel = opts?.label ? `[captureError] ${opts.label}` : '[captureError]'

  if (opts?.payload) {
    console.error(consoleLabel, error, opts.payload)
  } else {
    console.error(consoleLabel, error)
  }
}
