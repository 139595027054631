import type {
  Backend,
  BackendProjectImportConnection,
  BackendProjectImportConnectionBrowseRemoteResponsePayload,
  BackendProjectImportConnectionChangeConfigRequestPayload,
  BackendProjectImportConnectionListeners,
} from '~api'
import PhoenixConnection, { PhoenixConnectionListeners } from './phoenix-connection'

interface Listeners extends BackendProjectImportConnectionListeners, PhoenixConnectionListeners<object> {}

export default class MiPasaProjectImportConnection extends PhoenixConnection<Listeners, object> implements BackendProjectImportConnection {
  constructor(backend: Backend) {
    super(backend, `/api/mipasa/socket`, `project_import`)
  }

  changeConfig(config: BackendProjectImportConnectionChangeConfigRequestPayload) {
    const payload: Record<string, any> = { ...config }

    if (config.providerConfig) {
      delete payload.providerConfig

      payload.provider_config = {}

      if (config.providerConfig.accessToken) {
        payload.provider_config.access_token = config.providerConfig.accessToken
      }

      if (config.providerConfig.instanceUrl) {
        payload.provider_config.instance_url = config.providerConfig.instanceUrl
      }
    }

    return this.sendCommand('change_config', payload)
  }

  browseRemote(): Promise<BackendProjectImportConnectionBrowseRemoteResponsePayload> {
    return this.sendSynchronousCommand('browse_remote')
  }

  startImport(opts = {}) {
    return this.sendCommand('start_import', opts)
  }

  abortImport() {
    return this.sendCommand('abort_import')
  }

  async connect() {
    if (this.channel) {
      this.disconnect()
    }

    super.connect()
  }
}
