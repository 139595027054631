import { v4 as uuid } from 'uuid'
import { isMiPasa } from '~routes/platform'
import Base58 from '~utils/base58'

function generateNewGuestId() {
  const newGuestId = uuid().replace('-', '')
  const fromHexString = (hexString: string) => new Uint8Array((hexString.match(/.{1,2}/g) || []).map(byte => parseInt(byte, 16)))

  return Base58.encode(fromHexString(newGuestId))
}

export function resetGuestId() {
  if (!window.localStorage) {
    return
  }

  window.localStorage.guestId = generateNewGuestId()
}

export function getGuestId() {
  if (!window.localStorage || !isMiPasa()) {
    return undefined
  }

  if (!window.localStorage.guestId) {
    window.localStorage.guestId = generateNewGuestId()
  }

  return window.localStorage.guestId
}
