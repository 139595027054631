import type { NotebookAnalysisEntityExternalDataset } from '~api'
import type { ApiDataset, ApiDatasetFile, Dataset, DatasetFile, Visibility } from '~data/datasets'
import { authorizedJsonFetch } from '~utils/authorized-fetch'
import { formatBytesToHumanReadable } from '~utils/formatBytesToHumanReadable'

export interface ApiResponse<Data> {
  success: boolean
  error?: string
  data?: Data
}

interface MultifetchDatasetsRequest {
  datasets: Array<{
    uuid: string
    version: number
    files: string[]
  }>
}

interface MultifetchDatasetsData {
  datasets: Dataset[] | null
}

interface FetchDatasetsOpts {
  visibility: Visibility
  withFiles?: boolean
}

export const mapDatasetFiles = (files: ApiDatasetFile[] = []): DatasetFile[] =>
  files.map(file => ({
    ...file,
    name: file.version.filename,
    formattedSize: formatBytesToHumanReadable(file.version.size),
  }))

export const mapDataset = ({ version, ...dataset }: ApiDataset): Dataset => ({
  ...dataset,
  version: { ...version, files: mapDatasetFiles(version.files) },
})

export default class UnboundedBackend {
  private signal?: AbortSignal

  private prefix: string

  constructor(prefix: string) {
    this.prefix = prefix
  }

  fetchJson(...params: Parameters<typeof authorizedJsonFetch>) {
    const opts = params[1] || {}

    opts.signal = this.signal
    params[1] = opts

    return authorizedJsonFetch(...params)
  }

  withSignal(signal?: AbortSignal): UnboundedBackend {
    const newBackend = Object.assign(Object.create(Object.getPrototypeOf(this)), this)

    newBackend.signal = signal
    return newBackend
  }

  async fetchListDatasets(opts?: FetchDatasetsOpts): Promise<Dataset[]> {
    const params = new URLSearchParams()

    opts?.withFiles && params.set('with_files', 'true')
    opts?.visibility && params.set('visibility', opts.visibility)

    const { data, success: isSuccess, error }: ApiResponse<ApiDataset[]> = await this.fetchJson(`${this.prefix}/v1/datasets?${params.toString()}`)

    if (data && isSuccess && !error) {
      return data.map(mapDataset)
    }

    throw new Error(error || "Couldn't fetch datasets")
  }

  async multifetchDatasets(entities: NotebookAnalysisEntityExternalDataset[]): Promise<Dataset[]> {
    const body: MultifetchDatasetsRequest = {
      datasets: entities.map(({ id, version, file_ids }) => ({
        uuid: id,
        version,
        files: file_ids,
      })),
    }

    const {
      success: isSuccess,
      data,
      error,
    }: ApiResponse<MultifetchDatasetsData> = await this.fetchJson(`${this.prefix}/v1/datasets/multifetch`, {
      sendJson: true,
      method: 'POST',
      body,
    })

    if (data && isSuccess && !error) {
      return (data.datasets || []).map(mapDataset)
    }

    throw new Error(error || "Couldn't fetch datasets")
  }

  async fetchDatasetWithFiles(datasetId: string): Promise<Dataset> {
    const {
      data,
      success: isSuccess,
      error,
    }: ApiResponse<ApiDataset> = await this.fetchJson(`${this.prefix}/v1/datasets/${datasetId}?with_files=true`)

    if (data && isSuccess && !error) {
      return mapDataset(data)
    }

    throw new Error(error || "Couldn't fetch datasets")
  }
}
