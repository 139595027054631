import type { UserInfo } from '~api'
import type { FileId, FileVersionId } from '~data/file/file-type'

export type ThreadId = string | FileId

export enum CodeCommentThreadState {
  unchanged = 'unchanged',
  moved = 'moved',
  erased = 'erased',
  modified = 'modified',
}

export interface ThreadPosition {
  fileVersionId: FileVersionId
  line: number
}

export interface CodeCommentsThread {
  id: ThreadId
  line: number
  state: CodeCommentThreadState
}

export type CodeComments = Array<CodeCommentsThread>
export type CommentId = string

export interface Comment {
  id: CommentId
  threadId: ThreadId
  fileId: FileId
  text: string
  authorId: string
  author: UserInfo
  createdAt: number
  updatedAt?: number
}

export interface ThreadCommentBase {
  threadId: ThreadId
  fileId: FileId
}
export interface PositionedCommentBase {
  position: ThreadPosition
  fileId: FileId
}
export interface FileCommentBase {
  fileId: FileId
}
