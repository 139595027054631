type ScriptLoaderElement = HTMLScriptElement & { loadScriptPromise: Promise<void> }

export function loadScript(id: string, url: string) {
  let script = document.getElementById(id) as ScriptLoaderElement
  if (script) {
    if (!script.loadScriptPromise) {
      throw new Error(`element #${id} doesn't appear to a script loader element - go find yourself a unique id instead`)
    }
    return script.loadScriptPromise
  }

  script = document.createElement('script') as ScriptLoaderElement
  script.setAttribute('type', 'text/javascript')
  script.setAttribute('id', id)
  script.setAttribute('async', 'async')
  script.setAttribute('defer', 'defer')
  script.setAttribute('src', url)
  script.loadScriptPromise = new Promise((resolve, reject) => {
    script.onload = () => {
      resolve()
    }

    script.onerror = error => {
      reject(error)
    }
  })
  document.head.appendChild(script)

  return script.loadScriptPromise
}
