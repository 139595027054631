import {
  bindViewportCSSVars,
  initMiniApp,
  initSwipeBehavior,
  initViewport,
  retrieveLaunchParams,
  type LaunchParams,
  type RGB,
} from '@telegram-apps/sdk'
import { BaseColor, ThemeMode, themeColor, themes } from '@unbounded/unbounded-components'
import { CURRENT_PLATFORM, setIsTelegramMiniApp } from '~routes/platform'
import { captureError } from '~utils/monitoring'

export const prepareTelegramMiniApp = async () => {
  let telegramThemeMode: ThemeMode | undefined
  let launchParams: LaunchParams | undefined

  try {
    launchParams = retrieveLaunchParams()

    if (launchParams) {
      setIsTelegramMiniApp(true)

      // Report Bot API version in console
      console.info(`Telegram Bot API version: ${launchParams?.version || '?'}`)
    }
  } catch (e) {
    // Do nothing, if it's not a telegram app then error is expected
  }

  if (!launchParams) {
    return { telegramThemeMode: undefined }
  }

  /**
   * From here and below we try to initialize TMA components
   * We do in separate try/catch blocks because it's fine
   * if some (or all) of them will fail to load.
   * They just improve the UI/UX/colors of the app, but not vital
   */

  // Get theme based on Telegram client theme mode
  try {
    const [miniApp] = initMiniApp()
    telegramThemeMode = miniApp?.isDark ? ThemeMode.dark : ThemeMode.light
    const theme = themes[CURRENT_PLATFORM.themes[telegramThemeMode]]

    // Update/set bg colors according to the current theme
    const bgColor = themeColor(BaseColor.shade_0)({ theme }) as RGB
    document.documentElement.style.backgroundColor = bgColor
    document.body.style.backgroundColor = bgColor

    if (miniApp?.supports('setHeaderColor')) {
      miniApp.setHeaderColor(bgColor)
    }

    if (miniApp?.supports('setBackgroundColor')) {
      miniApp.setBgColor(bgColor)
    }
  } catch (e) {
    captureError(e, { label: `TMA v${launchParams.version}: Error initializing miniApp` })
  }

  // Disable vertical swipe to close/minimize the app
  // (available from Bot API v7.7)
  try {
    const [swipeBehavior] = initSwipeBehavior()

    if (swipeBehavior?.supports('disableVerticalSwipe')) {
      swipeBehavior.disableVerticalSwipe()
    }
  } catch (e) {
    captureError(e, { label: `TMA v${launchParams.version}: Error initializing swipeBehavior` })
  }

  // Initialize viewport, expand the app and bind CSS vars (for app height)
  try {
    const [viewportPromise] = initViewport()
    const viewport = await viewportPromise
    viewport?.expand()
    bindViewportCSSVars(viewport)
  } catch (e) {
    captureError(e, { label: `TMA v${launchParams.version}: Error initializing viewport` })
  }

  return { telegramThemeMode }
}
