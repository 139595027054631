import { Breakpoint, breakpointEnding, breakpointStarting } from '@unbounded/unbounded-components'

export const SIDEBAR_CONTROLS_SIZE = 49
export const SIDEBAR_MIN_WIDTH = 224
export const SIDEBAR_MAX_WIDTH_VW = 48
export const SIDEBAR_FLOATING_MAX_WIDTH_VW = 50
export const SIDEBAR_ANIMATION_DURATION = 150
export const SIDEBAR_PANEL_HEADER_HEIGHT = 44
export const SIDEBAR_PANEL_MIN_RESIZABLE_HEIGHT = SIDEBAR_PANEL_HEADER_HEIGHT
export const SIDEBAR_FLOATING_BREAKPOINT_PX = 1024
export const sidebarFloatingBreakpointValue = Breakpoint.lg
export const sidebarBottomBreakpointValue = Breakpoint.md
export const sidebarSideBreakpoint = breakpointStarting(sidebarBottomBreakpointValue)
export const sidebarBottomBreakpoint = breakpointEnding(sidebarBottomBreakpointValue)
export const sidebarFloatingBreakpoint = breakpointEnding(sidebarFloatingBreakpointValue)
