import type { ProjectDirectoryMetadata, ProjectFileMetadata } from '~api'
import type { CodingLanguage, CodingType } from '~components/code/type'
import type { CodeComments } from '~data/comment/comment-type'

export type FileId = string

export enum FileEntityType {
  file = 'file',
  directory = 'directory',
}

export interface File {
  id: FileId
  name: string
  language: CodingLanguage
  createdAt: number
  projectId: string
  description: string
  data?: string
  contentUrl?: string
  image?: string
  isPublic: boolean
  packages?: any
  parentId?: string
  publicGroup: string
  publicUrl?: string
  type: CodingType
  userId: string
  updatedAt?: number
  metadata?: ProjectFileMetadata
  directoryMetadata?: ProjectDirectoryMetadata
  size?: number
  treeType: 'file' | 'directory'
}

export type FileVersionId = string

export interface FileVersionSummary {
  id: FileVersionId
  fileId: FileId
  userId: string
  createdAt: number
}

export interface FileVersion extends FileVersionSummary {
  data?: string
  contentUrl?: string
  threads: CodeComments
  name?: string | null
  revisionedAt?: number | null
}

export interface RenameDirectoryPayload {
  projectId: string
  oldName: string
  newName: string
}

export interface DeleteDirectoryPayload {
  projectId: string
  name: string
}

interface BaseFileEntity {
  projectId: string
  name: string
  id: FileId
  parentId?: FileId
  isLoading?: boolean
  isLoaded?: boolean
  readOnly?: boolean
  virtual?: boolean
  predefinedName?: string
}

export interface FileEntityFile extends BaseFileEntity {
  type: FileEntityType.file
  file: File
  metadata?: ProjectFileMetadata
}

export interface FileEntityDirectory extends BaseFileEntity {
  type: FileEntityType.directory
  metadata?: ProjectDirectoryMetadata
}

export type FileEntity = FileEntityFile | FileEntityDirectory
