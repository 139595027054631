import { ExchangeType, SupportedExchange } from './exchanges.types'

import demoLogo from '~assets/crypto/demo.svg'
import demoIcon from '~assets/logo_crypto.svg'

import binanceTestnetLogo from '~assets/crypto/binance_testnet.svg'
import binanceTestnetIcon from '~assets/crypto/binance_testnet_logo.svg'

import binanceLogo from '~assets/crypto/binance.svg'
import binanceIcon from '~assets/crypto/binance_logo.svg'

import krakenLogo from '~assets/crypto/kraken.svg'
import krakenIcon from '~assets/crypto/kraken_logo.svg'

import kucoinLogo from '~assets/crypto/kucoin.svg'
import kucoinIcon from '~assets/crypto/kucoin_logo.svg'

import gateioLogo from '~assets/crypto/gateio.svg'
import gateioIcon from '~assets/crypto/gateio_logo.svg'

export const SUPPORTED_EXCHANGES: Record<ExchangeType, SupportedExchange> = {
  [ExchangeType.demo]: {
    id: ExchangeType.demo,
    name: 'Pasa Demo',
    logo: demoLogo,
    icon: demoIcon,
  },
  [ExchangeType.binanceTestnet]: {
    id: ExchangeType.binanceTestnet,
    name: 'Binance Testnet',
    logo: binanceTestnetLogo,
    icon: binanceTestnetIcon,
  },
  [ExchangeType.binance]: {
    id: ExchangeType.binance,
    name: 'Binance',
    logo: binanceLogo,
    icon: binanceIcon,
  },
  [ExchangeType.kraken]: {
    id: ExchangeType.kraken,
    name: 'Kraken',
    logo: krakenLogo,
    icon: krakenIcon,
    isComingSoon: true,
  },
  [ExchangeType.kucoin]: {
    id: ExchangeType.kucoin,
    name: 'KuCoin',
    logo: kucoinLogo,
    icon: kucoinIcon,
    isComingSoon: true,
  },
  [ExchangeType.gateio]: {
    id: ExchangeType.gateio,
    name: 'Gate.io',
    logo: gateioLogo,
    icon: gateioIcon,
    isComingSoon: true,
  },
}
