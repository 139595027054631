import type { BackendContainerConnection, BackendContainerConnectionListeners } from '../../common/backend'
import WebSocketConnection from './websocket-connection'

export default class PapillonEthereumConnection
  extends WebSocketConnection<BackendContainerConnectionListeners>
  implements BackendContainerConnection
{
  constructor(projectId: string) {
    const wsProto = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
    const wsUrl = `${wsProto}//${window.location.host}/wsapi/projects/${projectId}`

    super(wsUrl)
    this.connect()
  }

  protected processMessage(message: any) {
    if (message.type === 'container-response') {
      const { response, payload } = message

      this.callListeners('response', response, payload)
    }
  }

  sendCommand(command: string, payload: any): void {
    this.sendMessage({ type: 'container-command', command, payload })
  }
}
