import type { ExecutionId } from '~data/execution/execution-type'
import type { BackendExecutionConnection, BackendExecutionConnectionListeners } from '../../common/backend'
import WebSocketConnection from './websocket-connection'

export default class PapillonExecutionConnection
  extends WebSocketConnection<BackendExecutionConnectionListeners>
  implements BackendExecutionConnection
{
  executionId: string

  constructor(executionId: ExecutionId) {
    const wsProto = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
    const wsUrl = `${wsProto}//${window.location.host}/wsapi/projects/asyncExecution`

    super(wsUrl)
    this.executionId = executionId
    this.addEventListener('open', () => {
      this.sendMessage({ type: 'execution-subscribe', executionId })
    })
    this.connect()
  }

  protected processMessage(message: any) {
    if (message.type === 'execution-output') {
      const { executionId, output } = message as { executionId: string; output: string }

      this.callListeners('output', executionId, output)
    }
  }

  sendInput(input: string): void {
    this.sendMessage({ type: 'execution-input', executionId: this.executionId, input })
  }
}
